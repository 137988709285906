<template>
  <div id="app" class="section">
    <div class="container">
      <Navbar />
      <router-view />
    </div>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar
  },
  created() {
    addEventListener('resize', () => {
      this.$store.commit('checkMobile');
    });
  }
}
</script>

<style>
@import "../node_modules/bulma/css/bulma.css";
@import "../node_modules/bulma-switch/dist/css/bulma-switch.min.css"
</style>
