<template>
  <div>
    <section class="section">
      <h1 class="heading is-size-3">40k Crusade Helper</h1>
    </section>
    <section class="content">
      <p>Crusade is fun but there is a lot of things to keep track of!</p>
      <p><b>Crusade Helper</b> aims to make it easier and let you spend more time slaying your foes!</p>
    </section>
    <section class="content">
      <p>You can get started without making an account but to save your list and progress you make you'll need to be logged in!</p>
    </section>
    <section>
      <div class="buttons">
        <router-link class="button" to="/orderofbattle">New Force</router-link>
        <router-link class="button is-primary" to="/account">Sign up / Log in</router-link>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
  }
};
</script>
