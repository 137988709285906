import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/orderofbattle",
    name: "Order Of Battle",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "orderofbattle" */ "../views/OrderOfBattle.vue")
  },
  {
    path: "/roadmap",
    name: "Roadmap",
    component: () => 
      import("../views/Roadmap.vue")
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import("../views/Account.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
