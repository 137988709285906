<template>
<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMain">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="navbarMain" class="navbar-menu">
        <div class="navbar-start">
            <router-link class="navbar-item" to="/">Home</router-link>
            <router-link class="navbar-item" to="/orderofbattle">Order of Battle</router-link>
            <router-link class="navbar-item" to="/roadmap">Roadmap</router-link>
        </div>
        <div class="navbar-end">
            <div class="navbar-item">
                <div class="buttons">
                    <router-link v-if="!this.$store.state.isLoggedIn" class="button is-primary" to="/account">Sign up / Log in</router-link>
                    <router-link v-if="this.$store.state.isLoggedIn" class="button is-primary" to="/account">Log out</router-link>
                </div>
            </div>
        </div>
    </div>  
</nav>  
</template>

<script>
document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});
export default {
  name: "Navbar",
  props: { }
}
</script>

<style scoped>

</style>