import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    isMobile: window.innerWidth <= 700,
    forces: [
      {
        name: "Example Force",
        faction: "Necron",
        playerName: "Ben", // todo remove this and store as part of account
        battleTally: 0,
        battlesWon: 0,
        requisitionPoints: 0,
        supplyLimit: 50,
        supplyUsed: 9,
        units: [
          {
            name: "Necron Warriors",
            power: 7,
            crusadePoints: 3
          },
          {
            name: "Scarabs",
            power: 2,
            crusadePoints: 0
          }
        ]
      },
      {
        name: "You can have multiple forces",
        faction: "Imperial",
        playerName: "Ben",
        battleTally: 3,
        battlesWon: 2,
        requisitionPoints: 5,
        supplyLimit: 50,
        supplyUsed: 10,
        units: [
          {
            name: "Some unit name",
            power: 5,
            crusadePoints: 5
          },
          {
            name: "Some unit name",
            power: 5,
            crusadePoints: 5
          }
        ]
      }
    ]
  },
  mutations: {
    checkMobile (state) {
      state.isMobile = window.innerWidth <= 700;
      console.log("checkMobile returning " + state.isMobile);
    },
    deleteUnit (state, forceName, index) {
      console.log("deleteUnit() forceName={}, index={}", forceName, index);
      state.forces[forceName].units.splice(index, 1)
    },
    login (state) {
      console.log("login()");
      state.isLoggedIn = true;
    },
    logout (state) {
      console.log("logout");
      state.isLoggedIn = false;
    }
  },
  actions: {},
  modules: {}
});
